import * as React from "react"
import Seo from "../components/seo";

const OauthHome = () => {

    return (
        <div className="App oauth-home container-fluid">
            <div className="row first-row align-items-center">
                <div className="info col-12 col-md-8 p-5 text-center d-flex flex-column justify-content-center align-items-center">
                    <h1 className="text-center flex-grow-0">RETRO FALL</h1>
                    <br/>
                    <p>Retro Fall will use your Google Play Games data to interact with the global leaderboard</p>
                    <p>Joining the leaderboards can make your experience playing Retro Fall more fun and engaging. </p>
                    <br/>
                    <p>view <a href="/privacy-policy">privacy policy</a></p>
                </div>
                <div className="col-12 col-md-4 text-center my-auto">
                    <h1 className="d-md-none text-center">GAMEPLAY</h1>
                    <img className="img-fluid my-auto" src="https://storage.googleapis.com/pcs-cdn-bucket/v1.0_preview.gif" alt="Retro Fall Gameplay"/>
                </div>

            </div>
        </div>
    );
}

export default OauthHome

export const Head = () => (
    <>
        <Seo title="Retro Fall - OAuth" description="Download on iOS and Android" />
    </>
);
